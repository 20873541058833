const NavDstEnum = {
    COMING_SOON: "/ComingSoon",
    HOME: "/",
    ABOUT_US: "/AboutUs",
    SERVICES: "/Services",
    OUR_WORK: "/OurWork",
    CONTACT_US: "/ContactUs"
};

export {
    NavDstEnum
};